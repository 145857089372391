.keyboard {
	width: 100%;
	max-width: 57em;
	margin: 0 auto;
	background: #F2F2F2;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
	font-size: 18px;
}

.keyboard-row {
	display: flex;
}

.keyboard-keysSet {
	display: flex;
	flex-grow: 1;
}

.keyboard-button {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-basis: 5.5em;
	height: 5.3333333333em;
	margin: 5px;
	border-radius: 8px;
	background-color: #FFF;
	border: 1px solid #cecece;
	font-size: 1em;
	font-family: "Proxima Nova Semibold", Arial, sans-serif;
	font-weight: 400;
}

.keyboard-button:focus {
	outline: none;
}

.keyboard-button:disabled {
	opacity: .4;
	cursor: default;
}

.keyboard-button:active {
	background-color: #ccc;
}

.keyboard-numberButton {
	flex-grow: 1;
}

.keyboard-halfButton {
	flex-basis: 2.75em;
}

.keyboard-space,
.keyboard-spaceButton{
	flex-grow: 1;
}

.keyboard-emailKey{
    flex-grow: 1;
}

.keyboard-utilButton {
	flex-grow: 2;
	max-width: 3em;
}

.keyboard-additionalButton {
	flex-basis: 7em;
}

.keyboard-submit-button {
	flex-basis: 8em;
	background-color: #63b324;
	border-color: #63b324;
	color: #fff;
}

.numeric-keyboard {
	width: auto;
}

.numeric-keyboard .keyboard-button {
	width: 4em;
	flex-basis: auto;
}

.numeric-keyboard .keyboard-submit-button {
	flex: 1;
	width: auto;
}

.numpad-next {
	flex-grow: 1;
}
